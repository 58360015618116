@import "../mixins.pcss";

@import "../breakpoints.css";

.limiter {
    width: 100%;
    margin: 0 auto;

    & .iti {
        width: 100%;
    }

    & .iti__selected-flag {
        padding: 0 18px 0 15px;
    }

    & .iti--allow-dropdown input {
        padding-left: 82px;
    }

    & .hide {
        display: none;
    }
}

.form {

    &__wrap {
        display: flex;
        flex-direction: column;
        width: 100%;

        & > * + * {
            margin-top: 16px;
        }
    }

    &__item {

        & .input__wrap {
            position: relative;
        }

        & > * + * {
            margin-top: 6px;
        }

        & input {
            width: 100%;
            border: 1px solid #e0e0e0;
            box-sizing: border-box;
            border-radius: 24px;
            padding: 14px 20px;
            font-size: 16px;
            line-height: 24px;
        }

        & label {
            margin-left: 20px;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;

            & span {
                color: var(--negative-color);
            }
        }
    }

    &__help {
        margin: 8px 0 0 20px;
        color: var(--alt-color);
        font-size: 16px;
        line-height: 20px;
        cursor: pointer;
        transition: font-weight .1s ease-in-out;

        &:hover {
            font-weight: 600;
            transition: font-weight .1s ease-in-out;
        }
    }

    &__button {
        margin-top: 28px;
        align-self: center;
        padding: 16px 0;
        width: 252px;
        font-size: 18px;
        line-height: 24px;


        @media(--mobile) {
            font-size: 16px;
            line-height: 20px;
        }
    }
}

.control-checkbox {
    margin-top: 64px;

    @media(--tablet) {
        margin-top: 28px;
    }

    & a {
        color: var(--alt-color);
        transition: font-weight .1s liner;

        &:hover {
            font-weight: 600;
            transition: font-weight .1s liner;
        }
    }
}

.login {

    &__container {
        box-sizing: border-box;
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background: #e5e5e5;
    }

    &__form {
        box-sizing: border-box;
    }

    &__wrap {
        box-sizing: border-box;
        background: #fff;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, .24), 0px 5px 10px rgba(0, 0, 0, .1), 0px 0px 12px rgba(0, 0, 0, .06);
        border-radius: 16px;
        background: #fff;
        padding: 72px 80px 80px 80px;
        max-width: 552px;

        @media(--tablet) {
            padding: 40px 24px;
        }

        @media(--mobile) {
            max-width: 288px;
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 48px;
        margin-right: 35px;
        box-sizing: border-box;

        @media(--mobile) {
            margin-bottom: 30px;
            margin-right: 0;
        }

        & > h2 {
            font-size: 32px;
            line-height: 40px;
            font-weight: bold;

            @media(--mobile) {
                font-size: 18px;
                line-height: 22px;
            }
        }

        & h2 + * {
            margin-left: 24px;


            @media(--tablet) {
                margin-left: 12px;
            }

        }

        & .title-notactive {
            font-size: 18px;
            line-height: 22px;
            color: #838383;
            cursor: pointer;
            transition: color .2s;

            &:hover {
                color: var(--btn-color);
            }

            @media(--tablet) {
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
            }
        }
    }

    &__return {
        position: relative;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 70px;
        font-size: 14px;
        line-height: 20px;
        color: var(--inactive-color);
        background: svg-load("../../assets/img/icons/arrow-return.svg") no-repeat;
        padding-left: 30px;
        right: 30px;
        background-position-y: center;
        background-size: 16px;
        transition: font-weight .1s ease-in-out;

        &:hover {
            font-weight: 600;
        }

        @media(--mobile) {
            margin-bottom: 36px;
            right: 0;
        }

    }
}

.revovery__password-wrap {
    padding: 34px 80px 124px;

    @media(--mobile) {
        padding: 26px 24px 82px;
    }
}
